import { navigateTo, useRoute, useRouteParams } from '#imports'
import type { Ref } from 'vue'

import type {
  BasePageContent,
  ParentPageInfos,
} from '@backmarket/http-api/src/api-specs-content'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { merge } from '@backmarket/utils/object/merge'

import { PAGE_TYPE_WITH_NO_PARAMS_CORRECTION } from '../service/content-service.config'

function shouldCheckRouteParams(
  data: BasePageContent,
): data is BasePageContent & { parentPageInfos: ParentPageInfos } {
  const shouldIgnoreRouteParams =
    isEmpty(data.parentPageInfos) ||
    PAGE_TYPE_WITH_NO_PARAMS_CORRECTION.includes(data.pageType)

  return !shouldIgnoreRouteParams
}

export function useHandleIncorrectRouteParams(
  data: Ref<BasePageContent | null>,
) {
  if (!data.value) return

  if (!shouldCheckRouteParams(data.value)) return

  const { parentPageInfos } = data.value

  const currentRouteParams = useRouteParams()

  const parentPageName = parentPageInfos.pageName

  const correctedPageNameParam =
    currentRouteParams.pageName &&
    currentRouteParams.pageName !== parentPageName
      ? {
          pageName: parentPageName,
        }
      : undefined

  if (isEmpty(correctedPageNameParam)) {
    return
  }

  const redirect = merge({}, useRoute(), {
    params: {
      ...currentRouteParams,
      ...correctedPageNameParam,
    },
  })

  void navigateTo(redirect, { redirectCode: 301 })
}
