<template>
  <!-- name is a reserved NuxtPage prop https://nuxt.com/docs/api/components/nuxt-page#props -->
  <NuxtPage
    v-if="data && isSpecificLayout"
    v-bind="omit(data, ['name'])"
    data-test="specific-layout"
  />

  <DefaultLayout
    v-if="data && !isSpecificLayout"
    v-bind="data"
    data-test="default-layout"
    :simpleLayout
  />
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'

import { omit } from '@backmarket/utils/object/omit'

import { useCMSHead } from './composables/useCMSHead'
import { useFetchContent } from './composables/useFetchPageContent'
import { useHandleFetchError } from './composables/useHandleFetchError'
import { useHandleIncorrectRouteParams } from './composables/useHandleIncorrectRouteParams'
import { useIsSpecificLayout } from './composables/useIsSpecificLayout'
import DefaultLayout from './layout/Default.vue'

withDefaults(defineProps<{ simpleLayout?: boolean }>(), { simpleLayout: false })

const { FF_ENABLE_CONTENT_SERVICE_ON_DEFAULT_CMS_PAGES } =
  useRuntimeConfig().public

const { data, error } = await useFetchContent(
  FF_ENABLE_CONTENT_SERVICE_ON_DEFAULT_CMS_PAGES,
)

useHandleFetchError(error)
useHandleIncorrectRouteParams(data)
useCMSHead(data)
const isSpecificLayout = useIsSpecificLayout(data)
</script>
